<template>
  <div class="match">
    <div class="list flex_wrap">
      <div
        class="item transition flex_direction_column"
        v-for="(item, index) in list"
        :key="index"
        @click="itemClick(item)"
      >
        <div class="item_top flex_wrap"><span v-if="item.type == 2">线上赛</span> {{ item.name }}</div>
        <div class="item_middle flex_space_between">
          <div class="item_middle_left">
            <img :src="item.cover" />
          </div>
          <div class="item_middle_center flex_direction_column">
            <span> 参赛地点：{{ item.province + item.city }}</span>
            <span> 报名时间：{{ item.sign_start }}</span>
            <span> 比赛时间：{{ item.start_time }}</span>
          </div>
          <div class="item_middle_right flex">
            <span :style="getStyle(item)">{{ getText(item) }}</span>
          </div>
        </div>
        <div class="item_bottom flex_wrap">
          <!-- <div class="item_bottom_box flex">
            <img src="@/assets/image/icon/03.png" />
            <span>{{ item.club_name }}</span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="pagination" v-if="isShow">
      <el-pagination
        background
        :page-size="15"
        :total="total"
        layout="prev, pager, next"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import statusMinxin from '@/minxin/status.js'
export default {
  mixins: [statusMinxin],
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    isShow: {
      type: Boolean,
      default() {
        return false
      }
    },
    total: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    itemClick(item) {
      if (item.is_url != 0) return window.open(item.url)
      this.$router.push({
        path: '/match-detail',
        query: { id: item.id }
      })
    },
    currentChange(page) {
      this.$emit('page', page)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-pager li {
  font-weight: normal;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: @color_one;
  color: #fff;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 29px;
}
.list {
  width: 100%;
  justify-content: space-between;
  .item {
    border-radius: 3px;
    width: 670px;
    cursor: pointer;
    border-radius: 3px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #eee;
    padding: 17px 20px 15px 20px;

    &:nth-child(2n) {
      margin-right: 0;
    }
    .item_top {
      min-height: 28px;
      // font-weight: bold;
      line-height: 28px;
      font-size: @font_size_20;
      span {
        width: 74px;
        height: 30px;
        color: #fff;
        line-height: 30px;
        margin-right: 10px;
        text-align: center;
        border-radius: 3px;
        font-weight: normal;
        display: inline-block;
        font-size: @font_size_16;
        background: linear-gradient(90deg, #ffbb00 0%, #f1893c 100%);
      }
    }
    .item_middle {
      margin-top: 15px;
      align-items: flex-start;
      position: relative;

      .item_middle_left {
        width: 158px;
        height: 106px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 3px;
          object-fit: cover;
        }
      }
      .item_middle_center {
        width: 72%;
        margin-left: 10px;
        color: @color_seven;
        font-size: @font_size_14;

        span {
          line-height: 30px;

          &:nth-child(1) {
            margin-top: -5px;
          }
        }
      }
      .item_middle_right {
        height: 100%;
        //margin-left: 78px;
        align-items: flex-end;
        right: 0px;
        position: absolute;

        span {
          height: 36px;
          width: 114px;
          color: #fff;
          line-height: 36px;
          text-align: center;
          border-radius: 3px;
          background-color: @color_three;
        }
      }
    }
    .item_bottom {
      margin-top: 9px;
      .item_bottom_box {
        img {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
        span {
          height: 20px;
          line-height: 20px;
          color: @color_eight;
          font-size: @font_size_14;
        }
      }
    }
  }
}
</style>
